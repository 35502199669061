import React, { useState, useEffect } from 'react';
import styles from './SinglePaneCarousel.module.css'; // Import the CSS module for custom styles
import carouselImage1 from '../images/jl.webp';
import carouselImage2 from '../images/tdl.webp';
import carouselImage3 from '../images/pe.webp';
import carouselImage4 from '../images/rvp.png';

const SinglePaneCarousel = () => {
    const [currentImage, setCurrentImage] = useState(0);



    const carouselImages = [
        { img: carouselImage1, alt: 'Screenshot of Janeenloehr.com', title: 'Janeenloehr.com', button: 'Visit', details: 'Longer description 1', text: 'Tech used: HTML, CSS, Javascript', url: 'https://www.janeenloehr.com' },
        { img: carouselImage2, alt: 'Screenshot of Tinydesignlab.ca', title: 'Tinydesignlab.ca', button: 'Visit', text: 'Tech Used: HTML, CSS, Javascript ', details: 'Longer description 2', url: 'https://www.tinydesignlab.ca' },
        { img: carouselImage3, alt: 'Screenshot of PrecambrianEarth.com', title: 'Precambrian Earth', text: 'Tech Used: HTML, CSS, Next JS', button: 'Visit', details: 'Longer description 3', url: 'https://www.precambrianearth.com' },
        { img: carouselImage4, alt: 'Screenshot of RESOLVingViolencePodcast.com', title: 'RESOLVing Violence', text: 'Tech Used: HTML, CSS, Next JS', button: 'Visit', details: 'Longer description 3', url: 'https://resolvingviolencepodcast.com' }

    ];




    const buttonClick = () => {
        window.open(carouselImages[currentImage].url, '_blank');
    }

    useEffect(() => {
        const next = (currentImage + 1) % carouselImages.length;

        const id = setTimeout(() => setCurrentImage(next), 5000);
        return () => clearTimeout(id);
        // eslint-disable-next-line
    }, [currentImage]);



    return (
        <div className={styles['carousel-container']}>

            <div className={styles['header-container']}>
                <h1>Recent Projects</h1>
            </div>
            {carouselImages.map((image, index) => (

                <div
                    key={index}
                    className={`${styles['carousel-inner']} ${currentImage === index ? styles['fade-in'] : styles['fade-out']}`}>


                    <div className={`${styles['carousel-text']} ${currentImage === index ? styles['fade-in'] : styles['fade-out']}`}>

                        <div className={`${styles['carousel-image-caption']} ${currentImage === index ? styles['fade-in'] : styles['fade-out']}`}>
                            <h2>{image.title}</h2>
                            <p>{image.text}</p>
                        </div>
                        <button className={`${styles['carousel-button']} ${currentImage === index ? styles['fade-in'] : styles['fade-out']}`}
                            onClick={() => buttonClick()}>{image.button}</button>



                    </div>
                    <div className={`${styles['carousel-image-container']} ${currentImage === index ? styles['fade-in'] : styles['fade-out']}`}>

                        <img src={image.img} alt={image.alt} className={`${styles['carousel-image']} ${currentImage === index ? styles['fade-in'] : styles['fade-out']}`} />

                    </div>

                </div>

            ))}


        </div>

    );
};

export default SinglePaneCarousel;
